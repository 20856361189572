@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --color-1: rgba(60, 63, 141, 1);
  --color-2: #10122F;
  --brown: rgba(200, 152, 67, 1);
  --blue:#234E70;
  --color-3:#C89843;
}
@media print {
  .report .print{
    display: none;
  }
}
@page {
  size: A4;
  margin: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: #f5f5f5 !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.44, rgb(122, 153, 217)),
    color-stop(0.72, rgb(73, 125, 189)),
    color-stop(0.86, rgb(28, 58, 148))
  );
}
.submit{
  padding: 0rem 5rem;
  background-color: var(--brown);
  color: white;
}
.submit2{
  padding: 1.1rem 1.4rem;
  background-color: var(--blue);
  color: white;
  border-radius: 2px !important;
}
.back{
  padding: 1.1rem 1.4rem;
  background-color: white;
  color:  var(--blue);
  border-radius: 2px !important;
  border: 1px solid var(--blue);
}
.text-color1{
  color:var(--color-2) !important
}
.required::before{
  content:"*";
  color: red;
  font-size: 1rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(250, 250, 250, 1);
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: black !important;
}
.input-general {
  border-radius: 7px !important;
  padding: 4px 9px !important;
  border: 1px solid #d9d9d9 !important;
  background: #ffffff !important;
  height: 36px !important;
  font-size: 0.9rem !important;
}
.input-addon-general input:focus {
  --tw-ring-color: inherit !important;
  border: 1px solid #d9d9d9 !important;
  --tw-ring-shadow: unset !important;
}
/* .input-addon-general  */
 .rc-virtual-list-holder-inner{
  overflow: hidden;
  flex-flow: wrap;
}
.input-addon-general input {
  border-radius: 7px 0 0 7px !important;
  padding: 4px 9px !important;
  border: 1px solid #d9d9d9 !important;
  background: #ffffff !important;
  height: 36px !important;
  @apply !text-xs;
}
.input-addon-general2 input {
  border-radius: 0 7px 7px 0 !important;
  padding: 4px 9px !important;
  border: 1px solid #d9d9d9 !important;
  background: #ffffff !important;
  height: 36px !important;
  font-size: 0.9rem !important;
}
.checkbox-disable {
  border: 1px solid rgba(217, 217, 217, 1) !important;
  color: rgba(0, 0, 0, 1);
  text-wrap: nowrap;
}
.checkbox-enable {
  border: 1px solid rgba(60, 63, 141, 1) !important;
  color: rgba(60, 63, 141, 1);
  background-color: rgba(228, 229, 242, 1);
  text-wrap: nowrap;
}
.checkbox-enable2 {
  border: 1px solid rgba(217, 90, 54, 1) !important;
  color: rgba(217, 90, 54, 1);
  background-color: rgba(255, 237, 231, 1);
  text-wrap: nowrap;
}
.title-1 {
  color: var(--color-1) !important;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
}
.important::before {
  content: '*';
  color: red;
  font-weight: bold;
}
  /****login*********/
  .login {
    background: url("./assets/bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vdh;
  }
/****result*********/
.a4-page {
  width: 210mm;
  /* min-height: 297mm; */
  margin: auto;
  /* border: 1px #D3D3D3 solid; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
.result table td{
  font-size: 8px;
  font-weight: normal ;
  text-wrap: nowrap;
}
.result  table th {
  font-size: 8px;
  font-weight: normal ;
  text-wrap: nowrap;
}
.result .HHLL{
  position: absolute;
  top:62%;
  right:47.5%;
  font-size: clamp(90%,1vh,0.95em);
}
.result .HHLL-2-1{
  position: absolute;
  top:63%;
  right:47.5%;
  font-size: clamp(90%,1vh,0.95em);
}
.result .HHLL-H{
  position: absolute;
  top:40%;
  right:64.75%;
  font-size: 0.9em;
}
.result .HHLL-H-4{
  position: absolute;
  top:30%;
  right:60%;
  font-size: 0.95em;
}
.result .HHLL-H-5{
  position: absolute;
  top:54%;
  right:25%;
  font-size: 0.95em;
}
.result .HHLL-H-6{
    position: absolute;
  top:44%;
  right:50%;
  color: white;
  font-size: 0.9em;
}
.result .HHLIL{
  position: absolute;
  top:78%;
  right:47.7%;
  color: white;
  font-size: clamp(90%,1vh,0.95em);
}
.result .HHLIL-H{
  position: absolute;
  top:58.5%;
  right:65%;
  color: white;
  font-size: 0.9em;
}

.result .HHLIL-H-4{
  position: absolute;
  top:310px;
  right:375px;
  color: white;
  font-size: 0.8em;
}
.result .HHLIL-H-5{
  position: absolute;
  top:273px;
  right:480px;
  color: white;
  font-size: 0.85em;
}
.result .HHLIL-H-6{
  position: absolute;
  top:260px;
  right:190px;
  color: white;
  font-size: 0.85em;
}
.result .D{
  position: absolute;
  top:41%;
  right:47%;
  font-size: 110%;
}
.result .D-7{
  position: absolute;
  top:41%;
  right:47%;
  font-size: 110%;
}
.result .D-H-6{
  position: absolute;
  top:160px;
  right:250px;
  font-size: 16px;
}

.result .L{
  position: absolute;
  top:50%;
  right:32%;
  font-size: 90%;
  transform: rotate(-90deg);
}
.result .L-7{
  position: absolute;
  top:50%;
  right:31%;
  font-size: 16px;
  transform: rotate(-90deg);
}
.result .L-H-6{
  position: absolute;
  top:160px;
  right:430px;
  font-size: 16px;
}
.result .hd{
  position: absolute;
  top:22%;
  right:60%;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .hd-7{
  position: absolute;
  top:22%;
  right:63%;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .hbn{
  position: absolute;
  top:42%;
  right:60%;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .hbn-7{
  position: absolute;
  top:41%;
  right:63%;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .ha{
  position: absolute;
  top:435px;
  right:500px;
  font-size: 1em;
}
.result .ha-2{
  position: absolute;
  top:57.6%;
  right:53%;
  font-size: 0.85em;
}
.result .hv-H{
  position: absolute;
  top:26%;
  right:71.3%;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .hv-H-4{
  position: absolute;
  top:75px;
  right:605px;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .hv-H-5{
  position: absolute;
  top:120px;
  right:640px;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .hv-H-6{
  position: absolute;
  top:150px;
  right:642px;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .hv-H-8{
  position: absolute;
  top:128px;
  right:642px;
  font-size: 1em;
  transform: rotate(-90deg);
}
.result .L1-H-5{
  position: absolute;
  top:87%;
  right:60%;
  font-size: 1.4em;
}
.result .L1-H-6{
  position: absolute;
  top:440px;
  right:600px;
  font-size: 1.1em;
}
.result .L2-H-5{
  position: absolute;
  top:87%;
  right:20%;
  font-size: 1.4em;
}
.result .L2-H-6{
  position: absolute;
  top:440px;
  right:375px;
  font-size: 1.1em;
}
.result .L3-H-6{
  position: absolute;
  top:440px;
  right:305px;
  font-size: 1.1em;
}
.result .L4-H-6{
  position: absolute;
  top:440px;
  right:175px;
  font-size: 1.1em;
}
.result .Hw-H-5{
  position: absolute;
  top:60%;
  right:34%;
  color: white;
  transform: rotate(-90deg);
  font-size: 1em;
}
.result .Hw-H-6{
  position: absolute;
  top:300px;
  right:287px;
  color: white;
  transform: rotate(-90deg);
  font-size: 1em;
}
.result .L-H-4{
  position: absolute;
  top:90px;
  right:460px;
  font-size: 1.3em;
}
.result .L-H-8{
  position: absolute;
  top:28%;
  right:460px;
  font-size: 1.2em;
}
.result .L-H-5{
  position: absolute;
  top:140px;
  right:460px;
  font-size: 1.4em;
}
.result .L-H{
  position: absolute;
  top:150px;
  right:440px;
  font-size: 1.3em;
}
.result .D-H-4{
  position: absolute;
  top:90px;
  right:320px;
  font-size: 1.3em;
}
.result .D-H-5{
  position: absolute;
  top:140px;
  right:300px;
  font-size: 1.4em;
}
.result .D-H-8{
  position: absolute;
  top:28%;
  right:33%;
  font-size: 1.2em;
}
.result .D-H{
  position: absolute;
  top:150px;
  right:310px;
  font-size: 1.3em;
}
.result .Hboot-H-4{
  position: absolute;
  top:330px;
  right:450px;
  font-size: 0.95em;
  transform: rotate(-90deg);
}
.result .Dboot-H-4{
  position: absolute;
  top:470px;
  right:360px;
  font-size: 0.95em;
}
.result .w_d-2-1{
  position: absolute;
  top:67%;
  right:38%;
  color: black;
  font-size:90%;
  transform: rotate(-90deg);
}

/**************************/
:where(.css-dev-only-do-not-override-1fumvat).ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  color: black !important;
}
:where(.css-dev-only-do-not-override-1fumvat).ant-btn-primary {
  color: #fff;
  background: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}
.custom-card .ant-btn-primary{
  background: #1677ff !important;
}
.ant-avatar{
  background-color: #B8CAD7 !important;
}
.project .ant-modal .ant-modal-content {
  padding: 4px;
}
.ant-select-single {
  height: 36px;
}
.ticket .ant-card-head {
  min-height: 50px !important;
  background: white !important;
}
.ant-card-head {
  min-height: 40px !important;
  background: rgba(228, 229, 242, 1) !important;
}
.ant-card-body {
  padding: 0.5rem !important;
}
.ant-menu-submenu-title{
  display: flex !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item, 
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  /* padding-inline-end:unset !important */
}
.ant-table-thead .ant-table-cell {
  background-color: #E2E9ED !important;
}
.ant-table-tbody .ant-table-cell{
  padding: 2px 8px !important;

}
.ant-select-focused:where(.css-dev-only-do-not-override-1fumvat).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector input{
  box-shadow:none !important ;
}
.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-title-content{
  font-weight: 600;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  padding: 0 0.6em !important;
}
.ant-select-selector, .ant-input, .ant-select-item {
  @apply !text-xs;
}
.ant-table table { 
  @apply !text-xs; }
/* .custom-card {
  position: relative;
}

.menu-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
} */
/*******invoice**********/


 .invoice main .inner-container {
  /* max-width: 800px; */
  margin: 0 auto;
}
 .invoice table.invoice {
  background: #fff;
}
 .invoice table.invoice .num {
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.8em;
}
 .invoice table.invoice tr,table.invoice td {
  background: #fff;
  text-align: left;
  font-weight: 400;
  color: #322d28;
}
 .invoice table.invoice tr.header td img {
  max-width: 300px;
}
 .invoice table.invoice tr.header td h2 {
  text-align: right;
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-size: 2rem;
  color: #1779ba;
}
 .invoice table.invoice tr.intro td:nth-child(2) {
  text-align: right;
}
 .invoice table.invoice tr.details > td {
  padding-top: 4rem;
  padding-bottom: 0;
}
 .invoice table.invoice tr.details td.id, table.invoice tr.details td.qty,
table.invoice tr.details th.id,
table.invoice tr.details th.qty {
  text-align: center;
}
 .invoice table.invoice tr.details td:last-child,
table.invoice tr.details th:last-child {
  text-align: right;
}
 .invoice table.invoice tr.details table thead,
table.invoice tr.details table tbody {
  position: relative;
}
 .invoice table.invoice tr.details table thead:after,
table.invoice tr.details table tbody:after {
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  margin-top: -1px;
  background: #c8c3be;
}
 .invoice table.invoice tr.totals td {
  padding-top: 0;
}
 .invoice table.invoice tr.totals table tr td {
  padding-top: 0;
  padding-bottom: 0;
}
 .invoice table.invoice tr.totals table tr td:nth-child(1) {
  font-weight: 500;
}
 .invoice table.invoice tr.totals table tr td:nth-child(2) {
  text-align: right;
  font-weight: 200;
}
 .invoice table.invoice tr.totals table tr:nth-last-child(2) td {
  padding-bottom: 0.5em;
}
 .invoice table.invoice tr.totals table tr:nth-last-child(2) td:last-child {
  position: relative;
}
 .invoice table.invoice tr.totals table tr:nth-last-child(2) td:last-child:after {
  content: "";
  height: 4px;
  width: 110%;
  border-top: 1px solid #1779ba;
  border-bottom: 1px solid #1779ba;
  position: relative;
  right: 0;
  bottom: -0.575rem;
  display: block;
}
 .invoice table.invoice tr.totals table tr.total td {
  font-size: 1.2em;
  padding-top: 0.5em;
  font-weight: 700;
}
 .invoice table.invoice tr.totals table tr.total td:last-child {
  font-weight: 700;
}
 .invoice .additional-info h5 {
  font-size: 0.8em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #1779ba;
}
/***************8*/

.report table td{
  font-size: 9px;
  font-weight: normal ;
  text-wrap: nowrap;
}
.report  table th {
  font-size: 9px;
  font-weight: normal ;
  text-wrap: nowrap;
}
.report .HHLL{
  position: absolute;
  top:65%;
  right:20%;
  line-height: 0.8rem;
  color: black;
  font-size: 1.2em;
}
.report .HHLL-2-1{
  position: absolute;
  top:66%;
  right:20%;
  line-height: 0.8rem;
  font-size: 1.2em;
}
.report .HHLL-H{
  position: absolute;
  top:32%;
  right:59%;
  line-height: 0.8rem;
  font-size: 1.4em;
}
.report .HHLL-H-4{
  position: absolute;
  top:30%;
  right:70%;
  font-size: 1.4em;
}
.report .HHLL-H-5{
  position: absolute;
  top:45%;
  right:20%;
  font-size: 1.4em;
}
.report .HHLL-H-6{
  position: absolute;
  top:40%;
  right:49%;
  line-height: 0.8rem;
  font-size: 1.2em;
}



.report .HHLIL{
  position: absolute;
  top:79%;
  right:20%;
  line-height: 0.8rem;
  color: black;
  font-size: 1.2em;
}
.report .HHLIL-2-1{
  position: absolute;
  top:77.5%;
  right:20%;
  color: black;
  font-size:1.3em;
}
.report .HHLIL-H{
  position: absolute;
  top:50%;
  right:59.5%;
  line-height: 0.8rem;
  color: black;
  font-size: 1.4em;
}
.report .HHLIL-H-4{
  position: absolute;
  top:64.5%;
  right:38%;
  line-height: 0.8rem;
  color: black;
  font-size: 1.4em;
}
.report .HHLIL-H-5{
  position: absolute;
  top:45%;
  right:60%;
  color: black;
  font-size: 1.4em;
}
.report .HHLIL-H-6{
  position: absolute;
  top:45%;
  line-height: .8rem;
  right:21%;
  color: black;
  font-size: 1.3em;
}
.report .w_d-2-1{
  position: absolute;
  top:67%;
  right:9%;
  color: black;
  font-size:1.3em;
  transform: rotate(-90deg);
}

.report .D{
  position: absolute;
  top:42%;
  right:20%;
  font-size: 16px;
}
.report .D-H-6{
  position: absolute;
  top:25%;
  right:28%;
  font-size: 1rem;
}

.report .L{
  position: absolute;
  top:50%;
  right:0;
  font-size: 16px;
  transform: rotate(-90deg);
}
.report .L-H-6{
  position: absolute;
  top:25%;
  right:45%;
  font-size: 1rem;
}
.report .hd{
  position: absolute;
  top:22%;
  right:41%;
  font-size: 1.5em;
  transform: rotate(-90deg);
}
.report .hbn{
  position: absolute;
  top:50%;
  right:41%;
  font-size: 1.5em;
  transform: rotate(-90deg);
}
.report .ha{
  position: absolute;
  top:58%;
  right:29%;
  font-size: 1.4em;
}

.report .hv-H{
  position: absolute;
  top:20%;
  right:74%;
  font-size: 1.4em;
  transform: rotate(-90deg);
}
.report .hv-H-4{
  position: absolute;
  top:15%;
  right:71.5%;
  line-height: 1.3rem;
  font-size: 1.2em;
  transform: rotate(-90deg);
}
.report .hv-H-5{
  position: absolute;
  top:19%;
  font-weight: 500;
  right:71.5%;
  line-height: 1.3rem;
  font-size: 1.3em;
  transform: rotate(-90deg);
}
.report .hv-H-6{
  position: absolute;
  top:21%;
  right:71.2%;
  font-size: 1.4em;
  transform: rotate(-90deg);
}
.report .hv-H-8{
  position: absolute;
  top:18%;
  right:71%;
  font-size: 1.4em;
  transform: rotate(-90deg);
}
.report .L1-H-5{
  position: absolute;
  top:84%;
  right:60%;
  font-size: 1.4em;
}
.report .L1-H-6{
  position: absolute;
  top:85%;
  right:70%;
  font-size: 1.4em;
}
.report .L2-H-5{
  position: absolute;
  top:84%;
  right:20%;
  font-size: 1.4em;
}
.report .L2-H-6{
  position: absolute;
  top:85%;
  right:42%;
  font-size: 1.4em;
}
.report .L3-H-6{
  position: absolute;
  top:85%;
  right:34%;
  font-size: 1.4em;
}
.report .L4-H-6{
  position: absolute;
  top:85%;
  right:21%;
  font-size: 1.4em;
}
.report .Hw-H-5{
  position: absolute;
  top:55%;
  right:33.5%;
  color: black;
  transform: rotate(-90deg);
  font-size: 1.4em;
}
.report .L-H-4{
  position: absolute;
  top:20%;
  right:50%;
  font-size: 1.4em;
}
.report .L-H-8{
  position: absolute;
  top:22%;
  right:50%;
  font-size: 1.4em;
}
.report .L-H-5{
  position: absolute;
  top:24%;
  right:50%;
  font-size: 1.4em;
}
.report .L-H{
  position: absolute;
  top:25%;
  right:55%;
  font-size: 1.4em;
}
.report .D-H-4{
  position: absolute;
  top:20%;
  right:35%;
  font-size: 1.4em;
}
.report .D-H-5{
  position: absolute;
  top:24%;
  right:35%;
  font-size: 1.4em;
}
.report .D-H-8{
  position: absolute;
  top:22%;
  right:35%;
  font-size: 1.4em;
}
.report .D-H{
  position: absolute;
  top:25%;
  right:35%;
  font-size: 1.4em;
}
.report .Hboot-H-4{
  position: absolute;
  top:65%;
  right:50%;
  font-size: 1.2em;
  transform: rotate(-90deg);
}
.report .Dboot-H-4{
  position: absolute;
  top:95%;
  right:38%;
  font-size: 1.4em;
}
.report .Hw-H-6{
  position: absolute;
  top:55%;
  right:32%;
  color: black;
  transform: rotate(-90deg);
  font-size: 1.4em;
}
.report .ha-2{
  position: absolute;
  top:59%;
  right:29%;
  font-size: 1.3em;
}
/*****************/
.a4-page-invoice {
  width: 210mm;
  min-height: 297mm;
  margin: auto;
  /* border: 1px #D3D3D3 solid; */
  background: white;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); */
}
/*******flowbite**********/
